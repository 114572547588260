import React, {useState, Fragment, useContext, useEffect, useRef} from 'react';
import {Nav, Navbar, Modal, Button, Form} from 'react-bootstrap';
import {AppContext} from '../App';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import {DemoModal} from "./DemoModals";
import { MDBBtn } from "mdbreact";
import {ContactModal} from "./ContactModals";


// NavBar component
export const NavigationBar = () => {
    const context = useContext(AppContext);

    const [showContact, setShowContact] = useState(false);
    // const [showReqDemo, setShowReqDemo] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [submitted, setSubmitted] =  useState(false);




    // resets state when you go into component
    useEffect(() => {
      setModalLoading(false);
      setSubmitted(false);
      setName('');
      setEmail('');
      setMessage('');
    }, [])


    // closing the contact modal
    const handleCloseContact = () =>{

      setShowContact(false);
      setEmail('')
      setName('')
      setMessage('')
      setModalLoading(false)
    } 

    const handleShowContact = () => setShowContact(true);

    // closing the req demo modal
    const handleCloseReqDemo = () => {
      // setShowReqDemo(false);
      setEmail('')
      setName('')
      setMessage('')
      setModalLoading(false)
    } 
    // const handleShowReqDemo = () => setShowReqDemo(true);

    // handling a contact submit form
    const handleContact = async () => {
      console.log("hello");
      if (name === '' || email === '' || message === '') {
        return;
      }
      else {
      setModalLoading(true);
      console.log("hello");
      fetch(`${context.base.get}contact` , {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            })
        })
            .then((response) => {
              if (response.status === 200) {
                // if it was sent close modal and empty everythiing
                setName('')
                setEmail('')
                setMessage('')
                setSubmitted(true);
              }
              else {
                console.log(response);
                console.log("there was an error ")

              }
            })
            .catch((error) => {
              console.error(error);
              setError(error);
              })}
      setModalLoading(false);
      document.getElementById("ContactForm").reset();
      };




      // handling request demo suubmiti form
      async function handleReqDemo() {
      console.log("hello");
      if (name === '' || email === '' || message === '') {
        return;
      }
      else {
      setModalLoading(true);
      console.log("hello");
      fetch(`${context.base.get}demo` , {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            })
        })
            .then((response) => {
              if (response === 'sent') {
                // if it was sent close modal and empty everythiing
                setName('')
                setEmail('')
                setMessage('')
                //handleCloseContact();
              }
              else {
                console.log("there was an error ")

              }
            })
            .catch((error) => {
              console.error(error);
              setError(error);
              })}
      setModalLoading(false);
      document.getElementById("DemoForm").reset();

      };



    // if modal is loading
    if (modalLoading) {
      return (
            <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
            </Spinner>
        );
    }


    return (

    <Fragment>

<ContactModal showContact={showContact} setShowContact={setShowContact} />
{/*<DemoModal showReqDemo={showReqDemo} setShowReqDemo={setShowReqDemo} />*/}


     <Navbar expand = "lg" style={{paddingLeft: "0px", paddingTop: 50, paddingBottom: 50}} className="collapseOnSelect nav-bar">
     <Navbar.Brand href="/"> Bountium </Navbar.Brand>
     <Navbar.Toggle aria-controls = "basic-navbar-nav" />
     <Navbar.Collapse id = "basic-navbar-nav" className = "justify-content-end">
        <Nav className = "ml-auto">
            <Nav.Item style={{paddingRight: 30}}  ><Nav.Link style = {{color: "black"}} href = "/">Home </Nav.Link> </Nav.Item>
            <Nav.Item style={{paddingRight: 30}}><Nav.Link style = {{color: "black"}} href = "/mission">Mission </Nav.Link> </Nav.Item>
            <Nav.Item style={{paddingRight: 30}}><Nav.Link style = {{color: "black"}} href = "/solution">Solution </Nav.Link> </Nav.Item>
            <Nav.Item style={{paddingRight: 30}}><Nav.Link style = {{color: "black"}} href = "/research">Research </Nav.Link> </Nav.Item>
            {/*<Nav.Item><Nav.Link href = "/Blog">Blog </Nav.Link> </Nav.Item>*/}
            {/*<Nav.Item style={{paddingRight: 20}}  onClick={()=> handleShowContact()}>        <Button style = {{borderRadius: 20, width: 100, paddingRight: 15, backgroundColor: '#2750DC'}} variant="dark" rounded>Contact</Button>*/}
            {/*</Nav.Item>*/}
        </Nav>
         <Button style = {{borderRadius: 20, width: 100, backgroundColor: "#000", borderColor : "#000" }} variant="primary" onClick={() =>  handleShowContact()}>Contact</Button>

     </Navbar.Collapse>
            {/*<Nav.Item onClick={()=> handleShowReqDemo()}> <Button style = {{borderRadius: 20, width: 150}} variant="dark"> Request Demo</Button> </Nav.Item>*/}


     </Navbar>
    </Fragment>
    )
}