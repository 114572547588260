import {Col, Card} from "react-bootstrap";
import React from "react";

export const DocAnalysisItem = (props) => {
    return (

<Col md={3} xs={4} lg={2} style = {{paddingBottom: 30 }}>
    {/*<Card style={{ height: '6rem', width: '6rem', boxShadow: "0 0 1px gray", padding: 15 }}>*/}
    {/*    <Card.Img  src={props.image} height  />*/}
    {/*</Card>*/}
    <div >
        <img src= {props.image}  style = {{maxWidth: 100,
            maxHeight: 100, cursor: "pointer"}} width="100" height="100" onClick={() =>  window.open(props.site)} />
    </div>
</Col>
)
}