import React, {Fragment, useEffect, useState} from 'react';
import {Row, Col, Card} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import purpleBlob from "./images/purpleBlobNew.png";
import orangeBlob from "./images/orangeBlobNew.png";
import greenBlob from "./images/greenBlobNew.png";



export const UtilityFeature = (props) => {

    return (
        <Fragment>
            {/*set background image for blobs*/}
        <Col xs={12} md={6} lg={4} style = {{paddingBottom: 20}} >
            <div style = {{
                // boxShadow: "0 0 5px gray", borderTop: "15px solid #2750DC",
                height: '100%'}}>


            <div style={{ paddingBottom: 50 , paddingTop: 25,
                height: 135, width: 135,
            }}>
        <Image src={props.link} square style = {{marginTop: 20, marginLeft: 10,  height: 100, width : 100, justifyContent: "center", alignItems: "center"}} />
            </div>

        <div>
        <h4 style = {{paddingBottom: 25, margin: 15, lineHeight: "1.7em" }}> {props.title} </h4>
            <p style = {{margin: 15, lineHeight: "1.7em", paddingBottom : 60}}>{props.desc}</p>
        </div>
            </div>

        </Col>
         </Fragment>


        
    )
}
