import React, {Fragment, useContext, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {UtilityFeature}  from './UtilityFeature';
import {Logo} from './Logo';
import {CaseStudy} from './CaseStudy';
import {Row, Col, Button} from 'react-bootstrap';
import {Projection} from './Projection';
import {TeamMember} from './TeamMember';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './style/overrides.css';  // change this to the file path of your overrides
import Image from 'react-bootstrap/Image';
import {AppContext} from '../App';
import {BasicFeature} from './BasicFeature';
import {TestimonialItem} from './TestimonialItem';
import Carousel from 'react-material-ui-carousel';
import { Layout } from './Layout';
import { Parallax, Background } from 'react-parallax';
import {DemoModal} from "./DemoModals";
import {DocAnalysisItem} from "./DocAnalysisItem";


export const Solution = () => {
    const context = useContext(AppContext);
    const [showReqDemo, setShowReqDemo] = useState(false);


    return (
        <Fragment>
            <div style = {{paddingBottom : 120, paddingTop: 120}}>
                {/*<Parallax*/}
                {/*    blur={10}*/}
                {/*    bgImage={'/assets/clouds.jpg'}*/}
                {/*    bgImageAlt="the cat"*/}
                {/*    strength={1000}*/}
                {/*    bgImageStyle = {{width: '100%', overflowX: "hidden", backgroundSize: "contain"}}*/}

                {/*>*/}
                {/*    <div style={{ height: '600px',*/}
                {/*    }} />*/}
                {/*</Parallax>*/}

                <div style={{paddingBottom: 120}}>
                    {/*<Image style={{*/}
                    {/*    position: "relative",*/}
                    {/*    width: "100%",*/}
                    {/*    height: "400px"*/}
                    {/*}}*/}
                    {/*       src={context.homePic.get}/>*/}

                    <Layout>
                        <div style={{
                            top: "40%",
                            // top: "300px",
                            width: "75%",
                            overflowX: "hidden"
                        }}>

                            <h1 style = {{paddingBottom: 20}}> {context.solutionHeading.get} </h1>
                            <h6 style = {{width: "70%", paddingBottom: 40}}> {context.solutionHeadingDesc.get} </h6>
                            <Button style = {{borderRadius: 20, width: 150, backgroundColor: "#2750DC", borderColor : "#2750DC"}} variant="dark" onClick={() =>  setShowReqDemo(true)}>Request Demo</Button>
                        </div>
                    </Layout>
                </div>
            </div>

            <DemoModal showReqDemo={showReqDemo} setShowReqDemo= {setShowReqDemo} />


            <Layout>
                {/*<h3 style = {{paddingBottom: 20}}>How it Works</h3>*/}
                <Row style = {{paddingBottom: 120}}>

                    <Row style = {{paddingBottom: 60}}>
                        <Col md={3}>
                            <Image src={context.solutionFeature.get.first.link} square style = {{marginTop: 20, marginLeft: 10,  height: 100, width : 100, justifyContent: "center", alignItems: "center"}} />
                        </Col>
                        <Col md={6}>
                            <h3>{context.solutionFeature.get.first.desc}</h3>
                            <p>{context.solutionFeature.get.first.text}</p>
                        </Col>

                    </Row>

                    <Row style = {{paddingBottom: 60}}>
                        <Col md={3}>
                            <Image src={context.solutionFeature.get.second.link} square style = {{marginTop: 20, marginLeft: 10,  height: 100, width : 100, justifyContent: "center", alignItems: "center"}} />
                        </Col>
                        <Col md={6}>
                            <h3>{context.solutionFeature.get.second.desc}</h3>
                            <p>{context.solutionFeature.get.second.text}</p>
                        </Col>

                    </Row>

                    <Row style = {{paddingBottom: 60}}>
                        <Col md={3}>
                            <Image src={context.solutionFeature.get.third.link} square style = {{marginTop: 20, marginLeft: 10,  height: 100, width : 100, justifyContent: "center", alignItems: "center"}} />
                        </Col>
                        <Col md={6}>
                            <h3>{context.solutionFeature.get.third.desc}</h3>
                            <p>{context.solutionFeature.get.third.text}</p>
                        </Col>

                    </Row>

                    {/*<UtilityFeature link = {context.solutionFeature.get.first.link} desc = {context.solutionFeature.get.first.text} title = {context.solutionFeature.get.first.desc}  background = {"orange"}  />*/}
                    {/*<UtilityFeature link = {context.solutionFeature.get.second.link} desc = {context.solutionFeature.get.second.text} title = {context.solutionFeature.get.second.desc}  background = {"green"}/>*/}
                    {/*<UtilityFeature link = {context.solutionFeature.get.third.link } desc = {context.solutionFeature.get.third.text} title = {context.solutionFeature.get.third.desc}  background = {"purple"} />*/}

                </Row>
            </Layout>

            <div style = {{paddingBottom: 160}}>
                <div style = {{backgroundColor: '#FCEDE4', paddingTop: 60, paddingBottom: 60}}>
                    <Layout>
                        <Row>

                            <Col md={5}>
                                <Image src= {context.dataAccess.get.pic} square  style = {{width : 360, height:236}}/>
                            </Col>

                            <Col md={2}></Col>

                            <Col md={5}>
                                <h3>
                                    Data Access and Streamlined Process
                                </h3>
                                <p>
                                    {context.dataAccess.get.desc}
                                </p>
                            </Col>

                        </Row>
                    </Layout>
                </div>
            </div>










            <Layout>
                {/*<h3 style = {{paddingBottom: 20}}> Capabilities </h3>*/}
                {/*<p style = {{paddingBottom: 25, marginRight: "25%"}}> {context.solutionCapabilities.get} </p>*/}

                <div style = {{paddingBottom : 120}}>
                    <Row>
                        <Col xs={12} md={5} style = {{marginRight: 25, paddingBottom: 90}}>
                            <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>

                            <h4> {context.solutionBasicFeature.get.first.title} </h4>
                            <p> {context.solutionBasicFeature.get.first.desc} </p>
                        </Col>

                        <Col md={1} style = {{paddingBottom: 90}}></Col>
                        <Col xs={12} md={5} >
                            <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>

                            <h4> {context.solutionBasicFeature.get.second.title} </h4>
                            <p> {context.solutionBasicFeature.get.second.desc} </p>
                        </Col>
                        <Col xs={12} md={5} style = {{marginRight: 25, paddingBottom: 90}}>
                            <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>

                            <h4> {context.solutionBasicFeature.get.third.title} </h4>
                            <p> {context.solutionBasicFeature.get.third.desc} </p>
                        </Col>
                        <Col md={1}></Col>

                        <Col xs={12} md={5} style = {{paddingBottom: 90}}>
                            <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>

                            <h4> {context.solutionBasicFeature.get.fourth.title} </h4>
                            <p> {context.solutionBasicFeature.get.fourth.desc} </p>
                        </Col>
                        <Col xs={12} md={5} style = {{marginRight: 25, paddingBottom: 90}}>
                            <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>

                            <h4> {context.solutionBasicFeature.get.fifth.title} </h4>
                            <p> {context.solutionBasicFeature.get.fifth.desc} </p>
                        </Col>
                        <Col md={1}></Col>

                        {/*<Col style = {{paddingBottom: 90}} xs={12} md={5}>*/}
                        {/*    <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>*/}

                        {/*    <h4> {context.solutionBasicFeature.get.sixth.title} </h4>*/}
                        {/*    <p> {context.solutionBasicFeature.get.sixth.desc} </p>*/}
                        {/*</Col>*/}

                        {/*<BasicFeature title = {context.solutionBasicFeature.get.first.title} desc = {context.solutionBasicFeature.get.first.desc} pic = {context.solutionBasicFeature.get.first.pic} dir = {"right"} />*/}
                        {/*<BasicFeature title = {context.solutionBasicFeature.get.second.title} desc = {context.solutionBasicFeature.get.second.desc} pic = {context.solutionBasicFeature.get.second.pic} dir = {"left"} />*/}
                        {/*<BasicFeature title = {context.solutionBasicFeature.get.third.title} desc = {context.solutionBasicFeature.get.third.desc} pic = {context.solutionBasicFeature.get.third.pic} dir = {"right"} />*/}
                        {/*<BasicFeature title = {context.solutionBasicFeature.get.fourth.title} desc = {context.solutionBasicFeature.get.fourth.desc} pic = {context.solutionBasicFeature.get.fourth.pic} dir = {"left"} />*/}
                        {/*<BasicFeature title = {context.solutionBasicFeature.get.fifth.title} desc = {context.solutionBasicFeature.get.fifth.desc} pic = {context.solutionBasicFeature.get.fifth.pic} dir = {"right"} />*/}
                        {/*<BasicFeature title = {context.solutionBasicFeature.get.sixth.title} desc = {context.solutionBasicFeature.get.sixth.desc} pic = {context.solutionBasicFeature.get.sixth.pic} dir = {"left"} />*/}
                    </Row>
                </div>
            </Layout>


            <div style = {{paddingBottom: 120}}>
                <Layout>
                    <h4 style = {{paddingBottom: 20}}> Integrates Directly With </h4>
                </Layout>

                <div style = {{paddingBottom: 90}}>

                    <Layout>

                        <Row>
                            <Logo link={context.homeIntegrationLogos.get.logo1} site = {context.homeIntegrationLogos.get.link1} size = {2}  />
                            <Logo link={context.homeIntegrationLogos.get.logo2} site = {context.homeIntegrationLogos.get.link2} size = {2}/>
                            <Logo link={context.homeIntegrationLogos.get.logo3} site = {context.homeIntegrationLogos.get.link3} size = {2}/>
                            <Logo link={context.homeIntegrationLogos.get.logo4} site = {context.homeIntegrationLogos.get.link4} size = {2}/>
                            <Logo link={context.homeIntegrationLogos.get.logo5} site = {context.homeIntegrationLogos.get.link5} size = {2}/>
                            <Logo link={context.homeIntegrationLogos.get.logo6} site = {context.homeIntegrationLogos.get.link6} size = {2}/>
                        </Row>
                    </Layout>

                </div>
            </div>

            <Layout >
                <div style = {{paddingBottom: 200}}>
                    <h4 style = {{paddingBottom: 30}}>Doc Analysis Partners</h4>
                    <Row>
                        <Col md={8} lg={10} xs={12}   >
                            <h5 style = {{paddingBottom:100}}>{context.docAnalyisDesc.get}</h5>
                        </Col>
                    </Row>

                    <Row>
                        {context.docAnalysisPartners.get.map( (item) =>
                            <DocAnalysisItem image = {item} />
                        )}
                    </Row>


                    {/*<Row style = {{paddingBottom: 120}}>*/}
                    {/*    <Col xs={12} md={4}>*/}
                    {/*        <h4>{context.solutionDocAnalysis.get.first.name}</h4>*/}
                    {/*        <p>{context.solutionDocAnalysis.get.first.desc}</p>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={12} md={4}>*/}
                    {/*        <h4>{context.solutionDocAnalysis.get.second.name}</h4>*/}
                    {/*        <p>{context.solutionDocAnalysis.get.second.desc}</p>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={12} md={4}>*/}
                    {/*        <h4>{context.solutionDocAnalysis.get.third.name}</h4>*/}
                    {/*        <p>{context.solutionDocAnalysis.get.third.desc}</p>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </div>
            </Layout>

            {/*<Layout>
                <div style = {{paddingBottom: 90}}>
                    <h4 style = {{paddingBottom: 15}}>Success Stories</h4>

                    <Row style = {{paddingBottom: 60}}>
                        <Col md={6}>
                            <h5 > {context.solutionTestimonialDesc.get} </h5>
                        </Col>
                    </Row>

                    <Carousel>
                        {
                            context.solutionTestimonials.get.map( item => <TestimonialItem desc={item.desc} pic = {item.pic} /> )
                        }
                    </Carousel>
                </div>

            </Layout>*/}


            {/*<Row style = {{paddingBottom: 35}}>*/}
            {/*<Col xs={12} md={4}>*/}
            {/*    <Image src="https://via.placeholder.com/300" square />*/}
            {/*    </Col>*/}
            {/*    */}
            {/*    <Col xs = {12} md = {4}>*/}
            {/*    <div class="caption center-block">*/}
            {/*    <h1> Demo CTA </h1>*/}
            {/*    <h4> Feature </h4>*/}
            {/*    <p>BlockChain is kool</p>*/}
            {/*    <Button variant="primary">Demo</Button>{' '}*/}
            {/*    </div>*/}
            {/*     </Col>*/}
            {/*</Row>*/}

            <div style = {{backgroundImage: "linear-gradient(to top right, #E0E4FF, #FCEDE4)", padding: 60 }}>
                <Layout>
                    <Row>
                        <Col md={4}>
                            <h4>Managing LCs is a pain</h4>
                        </Col>
                        <Col md={6}>
                            <p>{context.missionManageLC.get}</p>
                        </Col>
                        <Col md={2}>
                            <Button style = {{borderRadius: 20, width: 150, backgroundColor: "#000", borderColor: "#000"}} onClick={() =>  setShowReqDemo(true)}>Request Demo</Button>
                        </Col>

                    </Row>
                </Layout>
            </div>





        </Fragment>





    )
}
