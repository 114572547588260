import React, {useContext, Fragment, useState, useEffect} from 'react';
import {AppContext} from '../App';
import Spinner from "react-bootstrap/Spinner";
import {Button, Col, Form, Modal} from "react-bootstrap";
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import orangeDesktop from "./images/orangeBlobsDesktop.jpeg";
import {Layout} from './Layout';
import purpleBlobs from "./images/purpleBlobsDesktop.jpeg";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const DemoModal = ({showReqDemo, setShowReqDemo}) => {

    const context = useContext(AppContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] =  useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    // resets state when you go into component
    useEffect(() => {
        console.log("here!")
        setModalLoading(false);
        setSubmitted(false);
        setName('');
        setEmail('');
        setMessage('');
    }, [])

    // closing the req demo modal
    const handleCloseReqDemo = () => {
        setShowReqDemo(false);
        setEmail('')
        setName('')
        setMessage('')
        setModalLoading(false)
    }
    const handleShowReqDemo = () => setShowReqDemo(true);

    // handling request demo submit form
    async function handleReqDemo() {
        console.log("hello");
        if (name === '' || email === '' || message === '') {
            return;
        }
        else {
            setModalLoading(true);
            console.log("hello");
            fetch(`https://morning-ravine-51992.herokuapp.com/blog/demo` , {
                method: 'POST',
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                })
            })
                .then((response) => {
                    if (response === 'sent') {
                        // if it was sent close modal and empty everythiing
                        setName('')
                        setEmail('')
                        setMessage('')
                        //handleCloseContact();
                    }
                    else {
                        console.log("there was an error ")

                    }
                })
                .catch((error) => {
                    console.error(error);
                })}
        setModalLoading(false);
        document.getElementById("DemoForm").reset();

    };

    // if modal is loading
    if (modalLoading) {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    }

    return (
        <Fragment>

            <Dialog PaperProps={{
                style: {
                    backgroundImage: `url(${purpleBlobs})`,
                    boxShadow: 'none',
                },
            }} fullScreen open={showReqDemo} onClose={handleCloseReqDemo} TransitionComponent={Transition}>
                <div>
                    <Toolbar style = {{backgroundRadius: 5}}>
                        <Layout>
                        <Typography variant="h5" color= "dark">
                            Bountium
                        </Typography>
                        </Layout>

                        <IconButton style = {{marginLeft: "auto"}} edge="end" color="dark" onClick={handleCloseReqDemo} aria-label="close">
                            <CloseIcon />
                        </IconButton>

                    </Toolbar>


                <div style = {{marginLeft : 100, marginRight: '40%', paddingTop: 120}}>
                    <h2> Request Demo </h2>
                    <p> {context.reqDemoDesc.get} </p>

                    <Form id = "DemoForm">
                        <Form.Group controlId="formDemoName">
                            <Form.Control type="name" placeholder="Name" onChange = { (event) => { setName(event.target.value) } } />
                        </Form.Group>

                        <Form.Group controlId="formDemoEmail">
                            <Form.Control type="email" placeholder="Email" onChange = { (event) => { setEmail(event.target.value) } } />
                        </Form.Group>

                        <Form.Group controlId="formDemoMessage">
                            <Form.Control as="textarea" rows="3" placeholder="Message " onChange = { (event) => { setMessage(event.target.value) } } />
                        </Form.Group>

                        <Button style = {{borderRadius: 20, width: 100}} variant="dark" onClick={e =>  handleReqDemo() } type="submit">Send</Button>
                    </Form>
                </div>
                </div>

            </Dialog>


            {/* Request Demo  Modal */}
            {/*<Modal show={showReqDemo} onHide={handleCloseReqDemo}>*/}
            {/*    <Modal.Header closeButton>Bountium </Modal.Header>*/}
            {/*    <h2> Request Demo </h2>*/}
            {/*    <p> {context.reqDemoDesc.get} </p>*/}
            {/*    <Form id = "DemoForm">*/}
            {/*        <Form.Group controlId="formDemoName">*/}
            {/*            <Form.Control type="name" placeholder="Name" onChange = { (event) => { setName(event.target.value) } } />*/}
            {/*        </Form.Group>*/}

            {/*        <Form.Group controlId="formDemoEmail">*/}
            {/*            <Form.Control type="email" placeholder="Email" onChange = { (event) => { setEmail(event.target.value) } } />*/}
            {/*        </Form.Group>*/}

            {/*        <Form.Group controlId="formDemoMessage">*/}
            {/*            <Form.Control as="textarea" rows="3" placeholder="Message " onChange = { (event) => { setMessage(event.target.value) } } />*/}
            {/*        </Form.Group>*/}

            {/*        <Button variant="primary" onClick={e =>  handleReqDemo() } type="submit">Send</Button>*/}
            {/*    </Form>*/}
            {/*</Modal>*/}
        </Fragment>
    )





}