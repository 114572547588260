import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'


export const Logo = (props) => {
    return (
        <Fragment>
        <Col xs={6} md={props.size} style = {{paddingBottom: 20}}>
            <img src={props.link} style = {{cursor: "pointer"}}width="100" height="100" onClick={() =>  window.open(props.site)}/>

        </Col>

         </Fragment>


        
    )
}
