import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'

// need to add the on click to the press's website
export const RecentPress = (props) => {
    return (
        <Fragment>

<Row style = {{paddingBottom: 20}} >
         <Col xs = {6} lg = {2}>
         <Image src= {props.pic} square />
         </Col>
        
        <Col xs={6} lg ={2}>

        <div class="caption center-block">
        <h4> {props.title} </h4>
        <p> {props.type} </p>

        </div>

         </Col>

         </Row>
         
         </Fragment>


        
    )
}