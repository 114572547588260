import React, {Fragment, useContext, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {UtilityFeature} from './UtilityFeature';
import {Logo} from './Logo';
import {AppContext} from '../App';
import {Layout} from './Layout';
import {CaseStudy} from './CaseStudy';
import Image from 'react-bootstrap/Image'
import {Row, Col, Form} from 'react-bootstrap';
import {Projection} from './Projection';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './style/overrides.css';  // change this to the file path of your overrides
import {TestimonialItem} from './TestimonialItem';
import {DemoModal} from "./DemoModals";
import {ContactModal} from "./ContactModals";
import { Parallax, Background } from 'react-parallax';
import background from "./images/orangeBlobNew.png";
import {maxWidth} from "@material-ui/system";
import styled from "styled-components";


export const Home = () => {
    const context = useContext(AppContext);
    const [showReqDemo, setShowReqDemo] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [width, setWidth] = useState( () => {
            if(window.innerWidth < 768) {
                return "100%"
            }
            else {
                return "75%"
            }
        }
    )
    const [marginRightWidth, setMarginRightWidth] = useState(() => {
            if(window.innerWidth < 768) {
                return "0%%"
            }
            else {
                return "30%"
            }
        }
    )




// Use Title and Wrapper like any other React component – except they're styled!

    return (


        <Fragment style = {{overflowX: "hidden"}}>
            <div style={{paddingBottom: 200, paddingTop: 120}}>

                <Layout>

                    <div style={{
                        width: {width},
                    }}>
                        <h1 style = {{paddingBottom: 20}}> {context.homeTitle.get} </h1>
                        <Row>
                            <Col xs={7} md={9}>
                                <h5 style = {{paddingBottom: 40}}> {context.homeDesc.get} </h5>

                            </Col>
                        </Row>
                        <Button style = {{borderRadius: 20, width: 150, backgroundColor: "#2750DC", borderColor : "#2750DC" }} variant="primary" onClick={() =>  setShowReqDemo(true)}>Request Demo</Button>
                    </div>
                </Layout>
            </div>

            <DemoModal showReqDemo={showReqDemo} setShowReqDemo= {setShowReqDemo} />
            <img src={"/assets/gradientWave.png"} height={200} width={"100%"}></img>
            <Layout>

                <Container style={{paddingBottom: 160}}>
                    <div style={{ backgroundImage: "/assets/gradientWave.png"}}>
                    <Row>
                        <UtilityFeature link={context.homeUtilityLinks.get.first.link}
                                        desc={context.homeUtilityLinks.get.first.desc}
                        title = {context.homeUtilityLinks.get.first.title}               />

                        <UtilityFeature link={context.homeUtilityLinks.get.second.link}
                                        desc={context.homeUtilityLinks.get.second.desc}
                                        title = {context.homeUtilityLinks.get.second.title}
                        />
                        <UtilityFeature link={context.homeUtilityLinks.get.third.link}
                                        desc={context.homeUtilityLinks.get.third.desc}
                                        title = {context.homeUtilityLinks.get.third.title}
                        />

                    </Row>
                    </div>
                </Container>


            </Layout>

            <Layout>
                <Row>
                    <Col md={9}>
                        <h2 style={{fontWeight: "bold", paddingBottom : 60}}>We're on a mission to help beneficiaries, clients, and banks make the most of the LC process.</h2>
                    </Col>
                </Row>

                    <div style = {{marginRight: {marginRightWidth}, paddingBottom: 90 }}>
                        <Row style = {{paddingBottom: 25}}>
                            <Col md={1} xs={2}>
                        <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>
                            </Col>
                            <Col md={10} xs={10}>
                        <h3 style = {{display: "inline", padding: 5, backgroundColor: "#FCEDE4", boxDecorationBreak: "clone"}}>{context.homeFeatureBullets.get.first.title}</h3>
                            </Col>
                        </Row>

                        {window.innerWidth > 768 ?
                                <p style = {{paddingTop: 15, paddingBottom : 90, marginRight: "30%", marginLeft: 50}}>{context.homeFeatureBullets.get.first.desc}</p>
                                : null  }
                                <Row style = {{paddingBottom: 25}}>
                            <Col md={1} xs={2}>
                                <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>
                            </Col>
                            <Col md={10} xs={10}>
                                <h3 style = {{display: "inline", padding: 5, backgroundColor: "#FCEDE4", boxDecorationBreak: "clone"}}>{context.homeFeatureBullets.get.second.title}</h3>
                            </Col>
                        </Row>
                        {window.innerWidth > 768 ?
                            <p style = {{paddingTop: 15, paddingBottom : 90, marginRight: "30%", marginLeft: 50}}>{context.homeFeatureBullets.get.second.desc}</p>
                            : null  }

                        <Row style = {{paddingBottom: 25}}>
                            <Col md={1} xs={2}>
                                <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>
                            </Col>
                            <Col md={10} xs={10}>
                                <h3 style = {{display: "inline", padding: 5, backgroundColor: "#FCEDE4", boxDecorationBreak: "clone"}}>{context.homeFeatureBullets.get.third.title}</h3>
                            </Col>
                        </Row>
                        {window.innerWidth > 768 ?
                            <p style = {{paddingTop: 15, paddingBottom : 90, marginRight: "30%", marginLeft: 50}}>{context.homeFeatureBullets.get.third.desc}</p>
                            : null  }

                    </div>
            </Layout>

                <div style = {{backgroundColor: " #E0E4FF"}}>
                    <Layout>

                <Row style = {{paddingBottom : 60, paddingTop: 60}}>
                    <Col md={4} lg={3} style = {{paddingTop: 0}}>
                        <h1 style = {{fontSize : 120, marginRight: 35}}>30+</h1>
                    </Col>
                    <Col md={8} style = {{marginTop: 35 }}>
                        <h5>{context.homeDocParsing.get}</h5>
                    </Col>
                </Row>
                    </Layout>
                </div>




                {/*<h4> Applications </h4>*/}
                {/*<h6 style={{paddingBottom: 60}}>{context.homeApplicationDesc.get}</h6>*/}

                {/*<div style = {{paddingBottom: 20}}>*/}
                {/*<CaseStudy desc={context.homeCaseStudies.get.study1.desc} link={context.homeCaseStudies.get.study1.pic}*/}
                {/*           dir={"left"} />*/}
                {/*</div>*/}
                {/*<h5 style = {{paddingBottom: 100}}>Clients of LCs</h5>*/}

                {/*<div style = {{paddingBottom: 20}}>*/}

                {/*<CaseStudy desc={context.homeCaseStudies.get.study2.desc} link={context.homeCaseStudies.get.study2.pic}*/}
                {/*           dir={"left"}/>*/}
                {/*</div>*/}
                {/*<h5 style = {{paddingBottom: 120}}>Clients of LCs</h5>*/}



                {/*<h3 style={{paddingBottom: 15}}> Projected Results </h3>*/}
                {/*<Row className="justify-content-md-center">*/}
                {/*    <Projection stat={context.homeProjections.get.proj1.stat}*/}
                {/*                desc={context.homeProjections.get.proj1.desc}/>*/}
                {/*    <Projection stat={context.homeProjections.get.proj2.stat}*/}
                {/*                desc={context.homeProjections.get.proj2.desc}/>*/}
                {/*    <Projection stat={context.homeProjections.get.proj3.stat}*/}
                {/*                desc={context.homeProjections.get.proj3.desc}/>*/}
                {/*</Row>*/}


                {/*<h4 style = {{paddingBottom: 25}}>Automatic Document Parsing</h4>*/}

                {/*<div style = {{paddingBottom: 120}}>*/}
                {/*<Row>*/}
                {/*    <Col md={2}>*/}
                {/*        <div style={{ top: 50, backgroundImage: `url(${background})` , backgroundSize: "cover",*/}
                {/*            height: 150, width: 150,*/}
                {/*        }}>*/}
                {/*        <p style = {{fontSize: 80, fontStyle: "italic"}}> 30</p>*/}
                {/*        </div>*/}
                {/*    </Col>*/}

                {/*    <Col md={3}>*/}
                {/*        <h5>{context.homeDocParsing.get}</h5>*/}
                {/*    </Col>*/}

                {/*    <Col md={2}></Col>*/}
                {/*    <Col md={2}>*/}
                {/*        <div style={{ top: 50, backgroundImage: `url(${background})` , backgroundSize: "cover",*/}
                {/*            height: 150, width: 150,*/}
                {/*        }}>*/}
                {/*            <p style = {{fontSize: 80, fontStyle: "italic"}}> $4T</p>*/}
                {/*        </div>*/}
                {/*    </Col>*/}

                {/*    <Col md={3} style = {{paddingLeft: 50, float: "left"}}>*/}
                {/*        <h5>{context.homeDocParsing.get}</h5>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*</div>*/}


                <div style={{paddingBottom: 40, paddingTop: 40}}>

                    <Layout>

                    <h3 style = {{paddingBottom: 40, fontWeight : "bold"}}>Easy Integration with Top Companies</h3>

                            <Row>
                                <Logo link={context.missionLogos.get.logo1} site = {context.missionLogos.get.link1} size = {3}/>
                                <Logo link={context.missionLogos.get.logo2} site = {context.missionLogos.get.link2} size = {3}/>
                                <Logo link={context.missionLogos.get.logo3} site = {context.missionLogos.get.link3} size = {3}/>
                                <Logo link={context.missionLogos.get.logo4} site = {context.missionLogos.get.link4} size = {3}/>
                            </Row>
                        </Layout>

                    {/*<h4 style={{paddingBottom: 15}}> Easy-Integration With </h4>*/}

                    {/*/!*<div style={{backgroundColor: "#e8f4f8", paddingBottom: 30, paddingTop: 30}} >*!/*/}
                    {/*<Layout>*/}

                    {/*    <Row>*/}
                    {/*        <Logo link={context.homeIntegrationLogos.get.logo1} size = {2}  />*/}
                    {/*        <Logo link={context.homeIntegrationLogos.get.logo2} size = {2}/>*/}
                    {/*        <Logo link={context.homeIntegrationLogos.get.logo3} size = {2}/>*/}
                    {/*        <Logo link={context.homeIntegrationLogos.get.logo4} size = {2}/>*/}
                    {/*        <Logo link={context.homeIntegrationLogos.get.logo5} size = {2}/>*/}
                    {/*        <Logo link={context.homeIntegrationLogos.get.logo6} size = {2}/>*/}
                    {/*    </Row>*/}
                    {/*</Layout>*/}
                    {/*</div>*/}


                </div>

                {/*<h4 style={{paddingBottom: 25}}> Hear From Our Partners </h4>*/}
                {/*<div style = {{paddingBottom: 60}}>*/}
                {/*<Carousel >*/}
                {/*    {*/}
                {/*        context.homeTestimonials.get.map(item => <TestimonialItem desc={item.desc} pic={item.pic}*/}
                {/*                                                                  name = {item.name} role = {item.role}  />)*/}
                {/*    }*/}
                {/*</Carousel>*/}
                {/*</div>*/}

            <ContactModal showContact={showContact} setShowContact={setShowContact} />

            <div>
                <div style={{backgroundColor: "#FCEDE4", paddingBottom: 90, paddingTop: 90}}>
                    <Layout>
                       <h3 style = {{paddingBottom: 20}}>Why Now?</h3>
                        <Row>
                        <Col xs={12} md={10}>
                            <p style = {{marginRight: 15}}>{context.homeWhyNowDesc.get}</p>
                        </Col>
                        <Col xs = {6} md ={2}>
                            <Button style = {{borderRadius: 20, width: 150, backgroundColor: "#000", borderColor: "#000"}} onClick={() =>  setShowContact(true)}>Work With Us</Button>
                        </Col>
                        </Row>


                    </Layout>
                </div>
            </div>
            {/*</div>*/}
        </Fragment>
    )
}