
import React, {Fragment, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'


export const Article = (props) => {
    const [articleState, setArticleState] = useState(props.location.state);
    return (
        <Fragment>
        <h1>{articleState.title}</h1>
        <h5>{articleState.description} </h5>
        <p> {articleState.text} </p>
         </Fragment>
    );
}