import React, {Fragment, useState, useContext} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import {Home} from './Components/Home';
import {Mission} from './Components/Mission';
import {Solution} from './Components/Solution';
import {Research} from './Components/Research';
import {NoMatch} from './Components/NoMatch';
import {Article} from './Components/Article';
import { Layout } from './Components/Layout';
import {Footer} from './Components/Footer';
import {Blog} from './Components/Blog';
import { NavigationBar} from './Components/NavigationBar';

export const AppContext = React.createContext({});

function App() {

  // global project stuff
  const [base, setBase] = useState('https://morning-ravine-51992.herokuapp.com/');
  //  HOME COMPONENT STUFF

    const [homePic, setHomePic] = useState("https://via.placeholder.com/150");
    const [homeTitle, setHomeTitle] = useState("We make letters of credit a breeze.\n");
    const [homeDesc, setHomeDesc] = useState("Convert manual processes on paper documents into automatic workflows that prevent human error and save time.");


    // the link to the home utility feature pictures
    const [homeUtilityLinks, setHomeUtilityFeatures] = useState(
        {first: {link: "/assets/magnifyingGlass.png",
     desc: "Upgrade your document inspection from squinting at Word - or worse, a printout - and use our tools to zoom in on the vital details. Bountium works with PDFs, scanned physical documents, or - best of all - documents created right in Bountium by a beneficiary.",
                title: "Tech-Enabled Document Inspection"},

            second: {title: "Personalised, Paperless Processing", link: "/assets/smiley.png",
                desc: "Bountium personalises the experience of every one of your clients, allowing them  to reuse form data - such as their LC application - and apply for your LC in only two minutes. We can detect what questions trip first-time applicants up, saving your customer service team time. Most importantly - your clients will never touch a paper form again. Bad handwriters everywhere rejoice!"},
     third: {link: "/assets/checklist.png", title: "Checks, Controls, and Reduced Risk" , desc: "AML checks on applicants? Done. Sanction checks on beneficiaries? Crushed. Due authorisation? Nailed. Creditworthiness? Report generated and sent to the committee. Approving LCs has never been easier, and your clients will love your rapid turnaround timelines."},
     fourth: {link: "https://via.placeholder.com/225", desc: "This is a sentence"}});

    const [homeFeatureBullets, setHomeFeatureBullets] = useState({first : {title: "Gain Insight into the Process", desc: "Every party to an LC can access real-time data about their transaction when its being done on Bountium. Applicants know where they stand in their bank's process, beneficiaries know how and when the bank will review their documents, and each bank involved can communicate in real-time."},
    second : {title: "Experience Efficient, Automated Workflows", desc: "We have worked directly on dozens of LCs across dozens of organisations to learn what standard practices can and should be aided by software. We've deployed a number of automated tools, such as credit overflow detection, AML checks, and reusable LC applications, to save your bank and your clients time. Even better - our software intelligently detects manual work patterned work for us to help with - and our focused team is on constant standby to get to work helping your organisation."},
third : {title: "Foster Unparalled Trust", desc: "Today, more than ever, trust is vital to building a sound supply chain strategy. Bountium removes every hurdle to using an LC that keeps every business partner honest - get all the trust you need with none of the paperwork, risk, or confusion that paper LCs can cause."}});

    // Affiliated with Logos
    const [homeIntegrationLogos, setHomeIntegrationLogos] = useState({logo1: "/assets/nicnoLogoNew.png", link1: "https://www.ncino.com/", logo2: "/assets/swiftLogoNew.png", link2: "https://www.swift.com/", logo3: "/assets/docuSignLogoNew.png", link3: "https://www.docusign.com/",  logo4: "/assets/jackHenryLogoNew.png", link4: "https://www.jackhenry.com/pages/default.aspx", logo5: "/assets/fiservLogoNew.png", link5: "https://www.fiserv.com/", logo6: "/assets/plaidLogoNew.png", link6: "https://plaid.com/" });

    const [homeDocParsing, setHomeDocParsing] = useState("of the world's largest logistic providers, moving $4T collectively, are supported by Bountium, making things easy for you.");

    const [homeApplicationDesc, setHomeApplicationDesc] = useState("This is the Application description");
    // Case Study Pictures and decription
    const [homeCaseStudies, setHomeCaseStudies] = useState({study1 : {desc: "This is a case study", pic: "/assets/man.svg"},
     study2: {desc: "This is a Case Study", pic: "/assets/woman.svg"}});

    // Projections
    const [homeProjections, setHomeProjections] = useState({proj1: {stat: "100%", desc: "projection description"},
     proj2: {stat: "100%", desc: "projection description"}, proj3: {stat: "100%", desc: "projection description"}} );

    // Testimonials
    const [homeTestimonials, setHomeTestimonials] = useState([ {pic: "/assets/clock.svg",
        desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool",
        name: "Steve the CEO", role: "CEO"},
        {pic: "https://via.placeholder.com/250", desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool", name: "Steve the CEO", role: "CEO"}, {pic: "https://via.placeholder.com/250", desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool", name: "Steve the CEO", role: "CEO"}])


    // Why now description
    const [homeWhyNowDesc, setHomeWhyNowDesc] = useState("We can help your bank take its LC process fully digital at a crucial time - and process more LCs in a world economy that’s going to demand more precaution for new supply relationships. Contact us, and be one of the first banks to prepare your LC process for the new normal.");


    // ----------------------

    // Mission Component

    const [missionTrustEssential, setMissionTrustEssential] = useState("The Bountium team wants every business dealing to be fair, fast, and hassle-free. Boston-born but globe-storming, we won’t rest until everyone can do business together easily. Because when we can work together, we can grow together.");

    //  Testimonial Problems
    /* TODO we must remove this until people can get back to us
    const [missionTestDesc, setMissionTestDesc] = useState("We’ve worked with over 30 banks and dozens of businesses to figure out exactly what we can build to help the people working with LCs every day - Here’s what we’ve learned from some of the most thoughtful trade specialists in industry:");
    const [missionTestimonials, setMissionTestimonials] = useState([ {pic: "https://via.placeholder.com/250",
        desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool",
        name: "Steve the CEO", role: "CEO"},
        {pic: "https://via.placeholder.com/250", desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool",
            name: "Steve the CEO", role: "CEO"}, {pic: "https://via.placeholder.com/250",
            desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool",
            name: "Steve the CEO", role: "CEO"}])
    */

    // Solution

    const [missionSolutionDesc, setMissionSolutionDesc] = useState("LCs Have Never Been Easier");

    // Features of Solution
    const [missionFeatures, setMissionFeatures] = useState({feature1 : {pic: "https://via.placeholder.com/225", desc: "Feature Description Sentence",
    }, feature2 : {pic: "https://via.placeholder.com/225", desc: "Feature Description Sentence",}, feature3: {pic: "https://via.placeholder.com/225", desc: "Feature Description Sentence",
  }});

    const [missionBannerGradient, setMissionBannerGradient] = useState("/assets/CTAbannerGradient.png") ;
    // Demo Description
    const [missionDemo, setMissionDemo] = useState({pic: "https://via.placeholder.com/225", desc:  "Demo Description"});

    const [missionManageLC, setMissionManageLC] = useState("Find out how your bank could benefit from adopting a digital-first Letter of Credit process.");

    const [missionResources, setMissionResources] = useState([{name: "DigiCert"}, {name: "AWS"}, {name: "Gitlab"}, {name: "React"}
    , {name: "Python"}, {name: "Django"}, {name: "Ethereum"}, {name: "HyperLedger"}, {name: "Ripple"}, {name: "Quorum"},
        {name: "Ernst & Young"}
    , {name: "Adobe"}]);

    const [missionResourcesDesc, setMissionResourcesDesc] = useState("We used nothing but the best to deliver the LC solution to end all painful paper pushing");

    // The Team
    const [missionTeamDesc, setMissionTeamDesc] = useState("We're radically devoted to revolutionising trade itself - allow us to each tell you why, and where we're coming from");

    const [missionBackFront, setMissionBackFront] = useState({back: "Back Description", front: "Front Description", art : "Art description"})

    const [missionTeam, setMissionTeam] = useState({first: {name: "Steve Flanagan", role: "CEO", desc: "Steve brings the vision, drive, and direction to Bountium. Prior to Bountium, he founded a software consultation business and grew it to six figure revenue within six months. In the course of working with clients, trust nagged his business as a consistent inhibitor. Clients paid late. Clients shifted terms. Clients didn't know how to evaluate his firm for trustworthiness. Quickly, Steve recognised that this trust problem was endemic to all business dealings, and that it could be overcome with technology.", pic: "/assets/JPP-3419.jpeg"}});

    // Press
    const [missionPress, setMissionPress] = useState({ one : {title : "Bountium is lit", type: "blockchain", date: "11/22/99", pic: "https://via.placeholder.com/125", link: ""},
    two : {title : "Bountium is lit", type: "blockchain", date: "11/22/99", pic: "https://via.placeholder.com/125", link: ""}});

    const [missionMovingForward, setMissionMovingForward] = useState("Now might be the most historically important moment for trust in business to evolve. Our world, reeling from coronavirus and fraught with political tension, needs as much help as it can get to foster human partnerships across boundaries of all kinds. We need reliability. We need transparency. We need something more than paper and promise. Bountium is unilaterally and tirelessly committed to delivering trust through technology, to foster human connection. Right now, this will take the form of facilitating Letters of Credit, a tool we see as inherently useful yet massively inhibited by legacy process. Over time, we will develop solutions to go beyond Letter of Credit facilitation. We want to be your business's solution for projecting trustworthiness, and for finding trustworthy partners.");
    const [missionLogos, setMissionLogos] = useState({logo1: "/assets/underscoreLogoNew.png", link1 : "https://underscore.vc/",  logo2: "/assets/DRFLogoNew.png", link2: "https://www.dormroomfund.com/", logo3: "/assets/ideaLogoNew.png", link3 : "https://www.northeastern.edu/idea/", logo4: "/assets/shermanCenterLogoNew.png", link4 : "https://northeastern.edu/sherman/"});

    //-------------

    // Research

    const [researchHeading, setResearchHeading] = useState('This is the research heading');
    const [researchFeaturedArticle, setResearchFeaturedArticle] = useState({title : "Bountium is lit", desc : "ARticle description", type: "blockchain", date: "11/22/99", pic: "https://via.placeholder.com/125", link: ""});

    const [researchArticles, setResearchArticles] = useState({ one : {title : "Bountium is lit", type: "blockchain", date: "11/22/99", pic: "https://via.placeholder.com/125", link: ""},
    two : {title : "Bountium is lit", type: "blockchain", date: "11/22/99", pic: "https://via.placeholder.com/125", link: ""}});

    const [researchContactCTA, setResearchContactCTA] = useState("Contact the CTA lol");

    //-------------

    // Solution

    const [solutionHeading, setSolutionHeading] = useState("LCs Have Never Been Better");
    const [solutionHeadingDesc, setSolutionHeadingDesc] = useState("Our tools tame your bank or business’s LC process to help save everyone time, while using technology to fundamentally lower each party’s risk exposure.");
    const [solutionTestimonialDesc, setSolutionTestimonialDesc] = useState("This is the testimonial description");
    const [solutionTestimonials, setSolutionTestimonials] = useState([ {pic: "https://via.placeholder.com/250",
        desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool",
        name: "Steve the CEO", role: "CEO"},
        {pic: "https://via.placeholder.com/250", desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool", name: "Steve the CEO", role: "CEO"}, {pic: "https://via.placeholder.com/250", desc: "Bountium is the best company ever, honestly. All our needs were satisfied in a timely manner and the CEO is cool", name: "Steve the CEO", role: "CEO"}])


    const [solutionFeature, setSolutionFeatures] = useState({first: {link: "/assets/clock.svg",
     desc: "Your client applies online", text: "They can re-use applications to save time, and our software can help guide them through confusing terms - reducing the time your customer service spends on explaining terminology."},
     second: {link: "/assets/feedback.svg", desc: "You stay on top of things, all the way to payout", text : "Our tools help your check a client’s creditworthiness, AML compliance, and legal viability for every party and transaction detail involved. Better yet: Our team is standing at the ready to build more automatic analysis for you. Let your mind flow creative, and your keyboard relax."},
     third: {link: "/assets/transparency.svg", desc: "You get the best tech to analyse documents", text: "Bountium helps you analyse every document you’ll come across - documents created in Bountium, or parsing PDFs, or even scanned physical documents. We keep every party notified from redlining to activation to draft submission to payout. Transparent, fast, and easy!"}});

    const [dataAccess, setDataAccess] = useState({pic: "/assets/redlining.jpeg", desc: "See everything you need to know about this LC, including the client's financials, any party's transaction history, and every detail particular to this transaction right in our modern, sleek interface"})

    const [solutionCapabilities, setSolutionCapabilities] = useState("We’ve worked with 32 banks to figure out how we can improve their LC process - check out what we’re doing today, and let us know what we should do next ");
    const [solutionBasicFeature, setSolutionBasicFeature] = useState({first: {pic:"https://via.placeholder.com/150", title: " Application", desc: "Send your clients to an intuitive, personalised application for all their LC needs. Frequent LC users can leverage reusable template applications, and each question has clear, updateable text to minimise confusion. Say goodbye to paper applications and static PDFs!" },
    second : {pic:"https://via.placeholder.com/150", title: "Easy Data Access", desc: "See everything you need to know about this LC - including the client’s financials, any party’s transaction history, and every detail particular to this transaction - right in our modern, sleek interface." },
    third: {pic:"https://via.placeholder.com/150", title: "Compliance and Creditworthiness Insights", desc: "Bountium will make a first pass at finding compliance red flags for you by programmatically accessing AML and sanction lists, while you get the final sign-off. We also offer some straightforward creditworthiness math to let you do the thinking while we crunch numbers!" } ,
    fourth: {pic: "https://via.placeholder.com/150", title: "Redlining", desc: "No more 10-email threads that force you to spend hours squinting at LC_V4.1_MAY_10.docx! Our software lets every party redline the terms of an LC in-app, while detecting and flagging version changes to ensure you know exactly who changed what when."  },
    fifth: {pic: "https://via.placeholder.com/150", title: "State Tracking", desc: "Find every LC you’d ever need, searching by any parameter - party, status, transaction size, date, and more. You’ll know exactly what terms you’ve used before and exactly how it went for your client (as well as yourself) in the past!"}})

    const [solutionDocAnalysis, setSolutionDocAnalysis] = useState({first: {name: 'Group 1', desc: 'This is a a description'},
        second: {name: 'Group 2', desc: 'This is a a description'}, third: {name: 'Group 3', desc: 'This is a a description'}})

    const [docAnalyisDesc, setDocAnalysisDesc] = useState('We\'ve tracked down over 30 of the most important document providers for your bank to work with - and their documents can be converted into data on Bountium with no lift on your end.');

    const [docAnalysisPartners, setDocAnalysisPartners] = useState(["/assets/bnsf.gif", "/assets/crcc.png", "/assets/dhl.png",
        "/assets/Maersk.jpg", "/assets/msc .png", "/assets/unionpacific.jpg", "/assets/fedex-logo.png", "/assets/ups logo.jpeg", "/assets/home-banner-k-logo.png", "/assets/Deutsche-Post-DHL-Group.png", "/assets/soaring_nameunderblack.png", "/assets/download (6).png"]);
    // Footer

    const [footerInfo, setFooterInfo] = useState({location: "Boston, MA", mail: "Boston, MA"});

    // NavBar

    const [contactDesc, setContactDesc] = useState("Agree or disagree with us? Looking for sources? Have an idea for your own article? We’d love to hear about it. Shoot us a message and we’ll be in touch by the end of the day."
        );
    const [reqDemoDesc, setReqDemoDesc] = useState("We love nothing more than working directly with banks and businesses to make Bountium as useful as it can be. Drop us a line!");


    const store = {

      // global
        base: {get: base, set : setBase},
        // home

        homePic: {get: homePic, set: setHomePic},
        homeTitle: {get: homeTitle, set: setHomeTitle},
        homeDesc: {get: homeDesc, set: setHomeDesc},
        homeUtilityLinks: { get: homeUtilityLinks, set: setHomeUtilityFeatures },
        homeIntegrationLogos : {get: homeIntegrationLogos, set: setHomeIntegrationLogos },
        homeCaseStudies : {get : homeCaseStudies, set: setHomeCaseStudies},
        homeFeatureBullets: {get : homeFeatureBullets, set : setHomeFeatureBullets},
        homeApplicationDesc: {get: homeApplicationDesc, set: setHomeApplicationDesc},
        homeDocParsing: {get : homeDocParsing, set  : setHomeDocParsing},
        homeProjections : {get: homeProjections, set: setHomeProjections},
        homeTestimonials : {get: homeTestimonials, set: setHomeTestimonials},
        // missionTestDesc: {get : missionTestDesc, set: setMissionTestDesc},
        homeWhyNowDesc : {get : homeWhyNowDesc, set : setHomeWhyNowDesc},


        // mission
        missionTrustEssential: {get: missionTrustEssential, set: setMissionTrustEssential},
        missionSolutionDesc: {get: missionSolutionDesc, set: setMissionSolutionDesc},
        missionFeatures: {get: missionFeatures, set: setMissionFeatures},
        missionDemo : {get: missionDemo, set: setMissionDemo},
        missionTeamDesc: {get: missionTeamDesc, set: setMissionTeamDesc},
        missionTeam: {get : missionTeam, set: setMissionTeam},
        missionPress: {get: missionPress, set: setMissionPress},
        missionBannerGradient: {get: missionBannerGradient, set :setMissionBannerGradient},
        missionMovingForward: {get: missionMovingForward, set: setMissionMovingForward},
        // missionTestimonials: {get: missionTestimonials, set: setMissionTestimonials},
        missionManageLC: {get: missionManageLC, set: setMissionManageLC},
        missionResources: {get : missionResources, set: setMissionResources},
        missionResourcesDesc: {get  : missionResourcesDesc, set : setMissionResourcesDesc},
        missionBackFront: {get: missionBackFront, set: setMissionBackFront},
        missionLogos: {get : missionLogos, set : setMissionLogos},


        // research
        researchHeading : {get: researchHeading, set: setResearchHeading},
        researchFeaturedArticle : {get: researchFeaturedArticle, set: setResearchFeaturedArticle},
        researchArticles: {get: researchArticles, set: setResearchArticles},
        researchContactCTA: {get : researchContactCTA, set : setResearchContactCTA},

        // solution
        solutionHeading: {get : solutionHeading, set : setSolutionHeading},
        solutionHeadingDesc : {get : solutionHeadingDesc, set : setSolutionHeadingDesc },
        solutionTestimonialDesc: {get: solutionTestimonialDesc, set : setSolutionTestimonialDesc},
        solutionTestimonials: {get: solutionTestimonials, set: setSolutionTestimonials},
        solutionFeature: {get: solutionFeature, set : setSolutionFeatures},
        solutionCapabilities: {get : solutionCapabilities, set : setSolutionCapabilities},
        solutionBasicFeature: {get: solutionBasicFeature, set : setSolutionBasicFeature},
        solutionDocAnalysis: {get : solutionDocAnalysis, set : setSolutionDocAnalysis},
        docAnalyisDesc: {get: docAnalyisDesc, set: setDocAnalysisDesc},
        docAnalysisPartners: {get : docAnalysisPartners, set : setDocAnalysisPartners},
        dataAccess: {get: dataAccess, set: setDataAccess},


        // Footer
        footerInfo: {get : footerInfo, set: setFooterInfo},

        // NavBar

        contactDesc: {get: contactDesc, set: setContactDesc},
        reqDemoDesc: {get : reqDemoDesc, set: setReqDemoDesc}










    };

  return (
    <Fragment>
    <AppContext.Provider value={store}>
    <Layout>
      <NavigationBar />
    </Layout>
    <Router>
    <Switch>

    <Route exact path="/" component = {Home} />
    <Route exact path="/mission" component = {Mission} />
    <Route exact path="/solution" component = {Solution} />
    <Route exact path="/research" component = {Research} />
    {/*<Route exact path="/blog" component = {Blog} />*/}
    <Route exact path="/article/:title" component={Article} />
    <Route component = {NoMatch} />

    </Switch>
    </Router>
    <Footer />
    {/* </Layout>  */}
    </AppContext.Provider>
    </Fragment>
  );
}

export default App;
