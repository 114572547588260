import React, {useContext, Fragment, useState, useEffect} from 'react';
import {AppContext} from '../App';
import Spinner from "react-bootstrap/Spinner";
import {Button, Form, Modal} from "react-bootstrap";
import purpleBlob from "./images/purpleBlobNew.png";
import orangeDesktop from "./images/orangeBlobsDesktop.jpeg";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from '@material-ui/core/Typography';
import {Layout} from './Layout';


import purpleBlobs from "./images/purpleBlobsDesktop.jpeg";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export const ContactModal = ({showContact, setShowContact}) => {

    const context = useContext(AppContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    // resets state when you go into component
    useEffect(() => {
        setModalLoading(false);
        setSubmitted(false);
        setName('');
        setEmail('');
        setMessage('');
    }, [])


    const handleCloseContact = () =>{

        setShowContact(false);
        setEmail('')
        setName('')
        setMessage('')
        setModalLoading(false)
    }

    const handleShowContact = () => setShowContact(true);

    // handling a contact submit form
    const handleContact = async () => {
        if (name === '' || email === '' || message === '') {
            return;
        }
        else {
            setModalLoading(true);
            fetch(`https://morning-ravine-51992.herokuapp.com/blog/contact` , {
                method: 'POST',
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        // if it was sent close modal and empty everythiing
                        setName('')
                        setEmail('')
                        setMessage('')
                        setSubmitted(true);
                    }
                    else {
                        console.log(response);
                        console.log("there was an error ")

                    }
                })
                .catch((error) => {
                    console.error(error);
                })}
        setModalLoading(false);
        document.getElementById("ContactForm").reset();
        // handleCloseContact();
    };

        // if modal is loading
        if (modalLoading) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }

        return (
            <Fragment>

                <Dialog  PaperProps={{
                    style: {
                        backgroundImage: `url(${purpleBlobs})`,
                        boxShadow: 'none',
                    },
                }} fullScreen open={showContact} onClose={handleCloseContact} TransitionComponent={Transition}>
                    <div>
                        <Toolbar >
                            <Layout>
                            <Typography variant="h5" color= "dark">
                                Bountium
                            </Typography>
                            </Layout>

                            <IconButton style = {{marginLeft: "auto"}} edge="end" color="dark" onClick={handleCloseContact} aria-label="close">
                                <CloseIcon />
                            </IconButton>

                        </Toolbar>


                        <div style = {{marginLeft : 100, marginRight: '40%', paddingTop: 120}}>
                            <h2> Contact </h2>
                            <p> {context.contactDesc.get} </p>

                            <Form id = "ContactForm">
                                <Form.Group controlId="ContactFormName">
                                    <Form.Control type="name" placeholder="Name" onChange = { (event) => { setName(event.target.value) } } />
                                </Form.Group>

                                <Form.Group controlId="ContactFormEmail">
                                    <Form.Control type="email" placeholder="Email" onChange = { (event) => { setEmail(event.target.value) } } />
                                </Form.Group>

                                <Form.Group controlId="ContactFormMessage">
                                    <Form.Control as="textarea" rows="3" placeholder="Message " onChange = { (event) => { setMessage(event.target.value) } } />
                                </Form.Group>

                                <Button style = {{borderRadius: 20, width: 100}} variant="dark" onClick={e =>  handleContact() } type="submit" >Send</Button>
                            </Form>
                        </div>
                    </div>

                </Dialog>

                {/* Contact  Modal */}
                {/*<Modal style = {{    height: "auto", minHeight: "100%"}} show={showContact} onHide={handleCloseContact} animation={true} >*/}
                {/*    <div style={{ backgroundImage: `url(${purpleBlob})` , backgroundSize: "cover",*/}
                {/*        backgroundPosition: "50% 50%"*/}
                {/*    }}>*/}
                {/*        <Modal.Header closeButton>Bountium </Modal.Header>*/}
                {/*    <div style = {{padding: 15}}>*/}
                {/*    <h3> Contact Us </h3>*/}
                {/*    <p> {context.contactDesc.get} </p>*/}
                {/*    </div>*/}

                {/*    <Form id="ContactForm">*/}
                {/*        <Form.Group controlId="formContact" role = "form" style = {{padding: 15}}>*/}
                {/*            <div style = {{paddingBottom: 20}}>*/}
                {/*            <Form.Control type="name" placeholder="Contact name" onChange = { (event) => { setName(event.target.value) } } />*/}
                {/*            </div>*/}
                {/*            <div style = {{paddingBottom: 20}}>*/}
                {/*            <Form.Control type="email" placeholder="Email" onChange = { (event) => { setEmail(event.target.value) } } />*/}
                {/*            </div>*/}
                {/*            <div style = {{paddingBottom: 20}}>*/}

                {/*            <Form.Control as="textarea" rows="3" placeholder="Message " onChange = { (event) => { setMessage(event.target.value) } } />*/}
                {/*            </div>*/}
                {/*            <Button style = {{borderRadius: 20, width: 100}} variant="dark" onClick={handleContact}> Contact</Button>*/}
                {/*            {submitted ? <p>submitted </p> : <p></p>}*/}
                {/*        </Form.Group>*/}
                {/*    </Form>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
            </Fragment>
        )


    }