import React, {Fragment, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import {Link} from "react-router-dom";
import greenBlog from './images/greenBlobNew.png';
import { Redirect } from 'react-router';



// need to add the on click to the press's website
export const ResearchArticle = (props) => {
    const [redirect, setRedirect] = useState(false);

    function formatDate(date) {
        let d = new Date(date);
        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        let m = month[d.getMonth()];
        return `${m} ${d.getDay()}, ${d.getFullYear()}`
    }

    if (redirect) {
        return <Redirect
            to={{ pathname: `/article/${props.title}`, state: { title: props.title, description: props.description, text : props.text}}}
        />
    }

    return (
        <Fragment>

<Row style = {{paddingBottom: 45}}>
         <Col xs = {6} lg = {3}>
             {/*<div style={{backgroundImage: `url(${greenBlog})`  }}>*/}
         <Image src= {props.picture} square />
             {/*</div>*/}
         </Col>
        
        <Col xs={6} lg ={3}>

        <div class="caption center-block" style = {{}}>
            <Row>
                <Col>
        <h5 style = {{display: "inline", float: "left", padding: 5, backgroundColor: "#FCEDE4", boxDecorationBreak: "clone"}}> {props.title} </h5>
            <img src={"/assets/arrow.png"} style = {{cursor: "pointer", marginLeft : 25, verticalAlign: "middle"}}width="40" height="25" onClick={() => setRedirect(true)  }/>
                </Col>
            </Row>

            <p style = {{marginTop : 10,}}> {props.type} </p>
            <p>{formatDate(props.date)}</p>

        </div>

         </Col>
         </Row>
         
         </Fragment>


        
    )
}