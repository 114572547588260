import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Row, Col, Button} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import {AppContext} from '../App';
import {ResearchArticle} from './ResearchArticle';
import { Layout } from './Layout';
import {BlogItem} from "./BlogItem";
import {DemoModal} from "./DemoModals";
import {ContactModal} from "./ContactModals";
import greenBlog from './images/greenBlobNew.png';




//add date to articles
export const Research = () => {
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [blogComponents, setBlogComponents] = useState([]);
    const [showContact, setShowContact] = useState(false);
    const [showReqDemo, setShowReqDemo] = useState(false);



    function generateBlogItems(json) {
        let blogItems = []
        for (let article of json) {
            blogItems.push(<ResearchArticle title = {article.title} type = {article.description} picture = {article.picture} date = {article.date}  />);
        }
        setBlogComponents(blogItems);
    }

    useEffect( () => {
        async function getBlogItems() {
            console.log(context.base.get);
            setLoading(true);
            fetch(`https://morning-ravine-51992.herokuapp.com/blog/blogs` , {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    generateBlogItems(json);
                    console.log(blogComponents);
                })
                .then(() => {
                    setLoading(false);
                })
                .catch((error => {
                    console.log(error);
                }))
        }
        getBlogItems();

    }, [])


    

    return (
         <Fragment>
             <div style = {{top: 0, bottom: 0, height: 550}}>
             <Layout>
             <h1>Articles Coming Soon</h1>
             </Layout>
             </div>

        {/*<Layout>*/}
        
        {/*<div style = {{paddingBottom: 45, paddingTop: 90}}>*/}
        {/*  <h2> Research</h2>*/}
        {/*  <p> {context.researchHeading.get} </p>*/}
        {/*</div>*/}


        {/*  <div style = {{paddingBottom: 90}} >*/}
        {/*  <Row >*/}
        {/*  <Col xs={12} md={6}>*/}
        {/*<Image src="https://via.placeholder.com/250" />*/}
        {/*</Col>*/}
        
        {/*<Col xs = {12} md = {6}>*/}
        {/*<div class="caption center-block" style = {{marginTop : 40, paddingBottom : 120}}>*/}
        {/*    <Row>*/}
        {/*        <Col>*/}
        {/*<h2 style = {{float : "left"}}> {context.researchFeaturedArticle.get.title} </h2>*/}
        {/*    <img src={"/assets/arrow.png"} style = {{cursor: "pointer", marginLeft : 25, verticalAlign: "middle"}}width="40" height="25"/>*/}
        {/*        </Col>*/}
        {/*    </Row>*/}

        {/*    <h5> {context.researchFeaturedArticle.get.type} </h5>*/}
        {/*<p>{context.researchFeaturedArticle.get.desc} </p>*/}
        {/*</div>*/}
        {/* </Col>*/}
        {/*  </Row>*/}
        {/*  </div>*/}


        {/*  {blogComponents}*/}
        {/*  </Layout>*/}

        {/*     <DemoModal showReqDemo={showReqDemo} setShowReqDemo= {setShowReqDemo} />*/}


        {/*     <div style = {{backgroundImage: "linear-gradient(to top right, #FCEDE4, #E0E4FF)", padding: 60 }}>*/}
        {/*         <Layout>*/}
        {/*             <Row>*/}
        {/*                 <Col md={4}>*/}
        {/*                     <h4>Managing LCs is a pain</h4>*/}
        {/*                 </Col>*/}
        {/*                 <Col md={6}>*/}
        {/*                     <p>{context.missionManageLC.get}</p>*/}
        {/*                 </Col>*/}
        {/*                 <Col md={2}>*/}
        {/*                     <Button style = {{borderRadius: 20, width: 150, backgroundColor: "#000", borderColor: "#000"}} onClick={() =>  setShowReqDemo(true)}>Request Demo</Button>*/}
        {/*                 </Col>*/}

        {/*             </Row>*/}
        {/*         </Layout>*/}
        {/*     </div>*/}


          </Fragment>
    )
}
