import React, {Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import purpleBlob from "./images/purpleBlobNew.png";
import blueBlob from "./images/blueBlobNew.png";


export const TeamMember = (props) => {
    return (
        <Fragment>

<Row style = {{paddingBottom: 80}}>
         <Col xs = {12} lg = {6}>

                 <Image src= {props.link} square style = {{verticalAlign: "middle", margin: "auto", height: 260, width: 390, marginTop:50}} />
             {/*</div>*/}
         </Col>
        
        <Col xs={12} lg ={6}>

        <div class="caption center-block">
            <div style = {{marginTop : 50
            }}>

                <h3> {props.name} </h3>
        <h5> {props.role} </h5>
        <p> {props.desc} </p>
        </div>

        </div>

         </Col>

         </Row>
         
         </Fragment>


        
    )
}