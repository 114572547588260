import {Paper} from '@material-ui/core';
import {Row, Col} from 'react-bootstrap';
import React from 'react';
import Image from 'react-bootstrap/Image'


export const ResourceItem = (props) => {
    return (
        <Col md={6} lg={4} style = {{paddingBottom: 40}}>
            <img src={"/assets/bullet.png"} style = {{marginRight : 25, float : "left", verticalAlign: "middle", padding: 5, marginTop: 2 }}/>
            <h3 style = {{display: "inline", padding: 5, backgroundColor: "#FCEDE4", boxDecorationBreak: "clone"}}>
                {props.name}
                </h3>
        </Col>
    )
}