import React, {Fragment, useContext, useState} from 'react'
import {AppContext} from '../App';
import {UtilityFeature} from './UtilityFeature';
import {Row, Col} from 'react-bootstrap';
import {DemoCTA} from './DemoCTA';
import {TeamMember} from './TeamMember';
import {RecentPress} from './RecentPress';
import { Layout } from './Layout';
import Carousel from 'react-material-ui-carousel';
import {TestimonialItem} from './TestimonialItem';
import { Parallax, Background } from 'react-parallax';
import Button from "react-bootstrap/Button";
import {DemoModal} from "./DemoModals";
import {Logo} from "./Logo";
import {ResourceItem} from "./ResourceItem";

export const Mission = () => {
    const context = useContext(AppContext);
    const [showReqDemo, setShowReqDemo] = useState(false);


    var memberComponents = []
    for (var member in context.missionTeam.get) {
        memberComponents.push(<TeamMember desc = {context.missionTeam.get[member].desc} link = {context.missionTeam.get[member].pic}
         role = {context.missionTeam.get[member].role} name = {context.missionTeam.get[member].name} />);
    }

    var pressComponents = []
    for (var press in context.missionPress.get) {
        pressComponents.push(<RecentPress title = {context.missionPress.get[press].title} type = {context.missionPress.get[press].type}
        date = {context.missionPress.get[press].date} link = {context.missionPress.get[press].link} pic = {context.missionPress.get[press].pic} />);
    }
    // console.log("tea memebrs");
    // console.log(memberComponents);

    return (
        <Fragment>
        <Layout>
            <div style = {{paddingTop: 140, padingBottom : 90}}>
        <h1 style = {{paddingBottom: 20}}> Trust is Essential </h1>

                <Row>
                    <Col md={10}>

                    <p style = {{paddingBottom: 120, marginRight: "25%"}}> {context.missionTrustEssential.get} </p>
                    </Col>

                {/*    <Col md={8}>*/}
                {/*        <p style = {{paddingBottom: 120}} >{context.missionTestDesc.get}</p>*/}
                {/*    </Col>*/}

                </Row>
            </div>

        {/*<div style = {{paddingBottom: 160}}>*/}

        {/*<Carousel>*/}
        {/*    {*/}
        {/*        context.missionTestimonials.get.map( item => <TestimonialItem desc={item.desc}*/}
        {/*                                                                      pic = {item.pic} name = {item.name} role = {item.role} /> )*/}
        {/*    }*/}
        {/*</Carousel>*/}
        {/*</div>*/}
        </Layout>



            <div style = {{paddingBottom: 120}}>
            <div style = {{paddingBottom: 90, paddingTop: 90, backgroundImage: "linear-gradient(to top right, #E0E4FF, #FCEDE4)"}}>
                <Layout>
                    {/*<Col md={4}>*/}
                        <h2 style = {{fontWeight: "bold", paddingBottom: 20}}>Managing LCs is a pain</h2>
                    {/*</Col>*/}
                    <Row>
                    <Col md={6}>
                        <h5>{context.missionManageLC.get}</h5>
                    </Col>
                        <Col md={4}></Col>
                    <Col md={2}>
                        <Button style = {{borderRadius: 20, width: 150, backgroundColor: "#000"}} variant="dark" onClick={() =>  setShowReqDemo(true)}>Request Demo</Button>
                    </Col>

                </Row>
                </Layout>
            </div>
            </div>

            <DemoModal showReqDemo={showReqDemo} setShowReqDemo= {setShowReqDemo} />


            {/*<h2> Our Solution </h2>*/}
        {/*<p style = {{paddingBottom: 25}}> {context.missionSolutionDesc.get} </p>*/}

        {/*<h3 style = {{paddingBottom: 25}}> Value Propositions </h3>*/}
        {/*<Row style = {{paddingBottom: 90}}>*/}
        {/*<UtilityFeature link = {context.missionFeatures.get.feature1.pic} desc = {context.missionFeatures.get.feature1.desc} />*/}
        {/*<UtilityFeature link = {context.missionFeatures.get.feature2.pic} desc = {context.missionFeatures.get.feature2.desc} />*/}
        {/*<UtilityFeature link = {context.missionFeatures.get.feature3.pic} desc = {context.missionFeatures.get.feature3.desc} />*/}
        {/*</Row>*/}


        <Layout>
        {/*<DemoCTA link = {context.missionDemo.get.pic} desc = {context.missionDemo.get.desc} />*/}

        <h4 style = {{paddingBottom: 20}}> Our Team </h4>

            <Row>
                <Col md={8}>
        <h5 style = {{paddingBottom: 90}}> {context.missionTeamDesc.get} </h5>
                </Col>
            </Row>
            <div style = {{paddingBottom: 120}}>
        {memberComponents}
            </div>

        {/*<h2> Recent Press </h2>*/}

        {/*{pressComponents}*/}

        </Layout>

            <Layout>
                <div style = {{paddingBottom: 160}}>
            <h4 style = {{paddingBottom: 30
            }}> Our Resources</h4>
                    <h5 style = {{paddingBottom: 60}}>{context.missionResourcesDesc.get}</h5>

                    <Row>
                        {
                            context.missionResources.get.map( item =>
                                <ResourceItem name = {item.name} />
                        )
                        }
                    </Row>

                    {/*<p>{context.missionResources.get}</p>*/}
                </div>


            {/*    <div style = {{paddingBottom : 120}}>*/}
            {/*<Row>*/}
            {/*    <Col xs={12} md={4}>*/}
            {/*        <h4>Front End</h4>*/}
            {/*        <p>{context.missionBackFront.get.front}</p>*/}
            {/*    </Col>*/}
            {/*    <Col xs={12} md={4}>*/}
            {/*        <h4>Back End</h4>*/}
            {/*        <p>{context.missionBackFront.get.back}</p>*/}
            {/*    </Col>*/}
            {/*    <Col xs={12} md={4}>*/}
            {/*        <h4>Art</h4>*/}
            {/*        <p>{context.missionBackFront.get.art}</p>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*    </div>*/}

                <div style = {{paddingBottom: 120}}>

            <h2 style = {{paddingBottom: 30, fontWeight : "bold"}}>Plus, we're backed by the best</h2>
                    <Layout>

                        <Row>
                            <Logo link={context.missionLogos.get.logo1} size = {3}/>
                            <Logo link={context.missionLogos.get.logo2} size = {3}/>
                            <Logo link={context.missionLogos.get.logo3} size = {3}/>
                            <Logo link={context.missionLogos.get.logo4} size = {3}/>
                        </Row>
                    </Layout>
                </div>

            </Layout>



            {/*<Parallax*/}
            {/*    blur={10}*/}
            {/*    bgImage={'/assets/clouds.jpg'}*/}
            {/*    bgImageAlt="the cat"*/}
            {/*    strength={400}*/}
            {/*>*/}
            {/*    <div style={{ height: '320px' }} />*/}
            {/*</Parallax>            */}
                <div style = {{position: "relative", backgroundColor : "#FCEDE4", padding : 60}}>
                    <Layout>
                        <h3 style = {{paddingBottom: 20,
                          }}> Our Vision </h3>
                        <Row>
                            <Col lg={10} md={12} xs={12}>
                        <h6 style = {{bottom: 80}}> {context.missionMovingForward.get} </h6>
                            </Col>
                        </Row>
                    </Layout>
                </div>


        </Fragment>
    )
}

