import React, {useContext, Fragment} from 'react';
import {AppContext} from '../App';
import {Row, Col} from 'react-bootstrap';



export const Footer = (props) => {
    const context = useContext(AppContext);

    return (
        <Fragment>
        <div style = {{backgroundColor: "black", padding: "5px"}}>
        <div className = "main-footer">
        <div className = "container">
        <Row style = {{paddingBottom: 5}}>
        <Col xs = {8} lg = {6}>
        <p style = {{color: "white", fontSize: 18}}>Bountium </p>
            <p style = {{color: "white", fontSize: 15}}> Revolutionising trust in business</p>
        </Col>
        <Col xs = {3} lg = {3}>
        <ul className = "list-unstyled">
        <li style = {{color: "white", fontSize: 15}}>{context.footerInfo.get.location}</li>
        </ul>
        </Col>
        <Col xs = {3} lg = {3}>
        <ul className = "list-unstyled">
            <li style = {{color: "#3361FF", fontSize: 15}}>steve@bountium.com</li>
        </ul>
        </Col>
        </Row>
            <p style = {{color: "grey", fontSize: 15, marginRight: "10%"}}>Icons on this site were sourced from Flat Icon. All object icons, seen on Home and Solution are representing a
            glass, timer, and chatbox with stars were created by Freepik.</p>

        </div>
        </div>
        </div>
        </Fragment>

    )


}
